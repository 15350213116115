.page-sys-declare {
  .group {
    display: flex;
    padding: 5px;
    gap: 5px;
    margin-top: 10px;
    background-color: #eee;
    border-radius: 3px;
    overflow-x: auto;
    overflow-y: hidden;
  }

  .project {
    padding: 10px;
    border-radius: 3px;
    background-color: #fff;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.24);
    cursor: pointer;
    user-select: none;
    white-space: nowrap;

    &:hover {
      background-color: #e6f7ff;
    }
  }

  .sub-menu {
    display: inline-block;
    font-size: 16px;
    background-color: #eee;
    height: min-content;

    .menu-head {
      // mixin
      height: 50px;
      line-height: 50px;
      text-align: center;
      padding: 0 20px;
      background-color: #3671c9;
      color: #fff;
      height: 50px;
      line-height: 50px;
    }

    .menu-item {
      height: 50px;
      line-height: 50px;
      text-align: center;
      padding: 0 30px;
      min-width: 180px;
      cursor: pointer;
      position: relative;

      &:hover {
        background-color: #ddd;
      }

      .menu-item-indicator {
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  .content-wrap {
    display: flex;
    padding: 20px;
  }
}
