/* windicss layer base */

/* windicss layer components */

/* windicss layer utilities */
.bg-light-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 252, 252, var(--tw-bg-opacity));
}
.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
}
.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}
.bg-blue-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
}
.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
}
.hover\:bg-gray-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
}
.focus\:bg-gray-200:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
}
.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
}
.bg-gray-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
}
.bg-green-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
}
.bg-blue-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
}
.bg-blue-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
}
.bg-red-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
}
.bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
}
.\!bg-transparent {
  background-color: transparent !important;
}
.bg-opacity-50 {
  --tw-bg-opacity: 0.5;
}
.bg-opacity-85 {
  --tw-bg-opacity: 0.85;
}
.bg-no-repeat {
  background-repeat: no-repeat;
}
.bg-cover {
  background-size: cover;
}
.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
}
.border-gray-500 {
  --tw-border-opacity: 1;
  border-color: rgba(107, 114, 128, var(--tw-border-opacity));
}
.border-dark-100 {
  --tw-border-opacity: 1;
  border-color: rgba(60, 60, 60, var(--tw-border-opacity));
}
.rounded-full {
  border-radius: 9999px;
}
.rounded {
  border-radius: 0.25rem;
}
.rounded-sm {
  border-radius: 0.125rem;
}
.rounded-md {
  border-radius: 0.375rem;
}
.rounded-bl-lg {
  border-bottom-left-radius: 0.5rem;
}
.border-solid {
  border-style: solid;
}
.border-dashed {
  border-style: dashed;
}
.border-none {
  border-style: none;
}
.border-1 {
  border-width: 1px;
}
.border-2 {
  border-width: 2px;
}
.cursor-pointer {
  cursor: pointer;
}
.block {
  display: block;
}
.inline-block {
  display: inline-block;
}
.inline {
  display: inline;
}
.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.contents {
  display: contents;
}
.hidden {
  display: none;
}
.flex-col {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}
.items-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.justify-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.justify-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
.justify-evenly {
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  -webkit-justify-content: space-evenly;
  justify-content: space-evenly;
}
.flex-grow {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  -webkit-flex-grow: 1;
  flex-grow: 1;
}
.flex-shrink-0 {
  -ms-flex-negative: 0;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
}
.float-right {
  float: right;
}
.font-bold {
  font-weight: 700;
}
.h-screen {
  height: 100vh;
}
.h-full {
  height: 100%;
}
.h-10 {
  height: 2.5rem;
}
.h-6 {
  height: 1.5rem;
}
.h-20 {
  height: 5rem;
}
.h-16 {
  height: 4rem;
}
.h-2\/3 {
  height: 66.666667%;
}
.h-auto {
  height: auto;
}
.h-min {
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
}
.h-140 {
  height: 35rem;
}
.h-100 {
  height: 25rem;
}
.h-12\.5 {
  height: 3.125rem;
}
.h-120 {
  height: 30rem;
}
.h-126 {
  height: 31.5rem;
}
.h-30 {
  height: 7.5rem;
}
.h-150 {
  height: 37.5rem;
}
.h-35 {
  height: 8.75rem;
}
.h-15 {
  height: 3.75rem;
}
.h-45 {
  height: 11.25rem;
}
.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}
.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}
.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}
.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}
.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}
.text-\[200px\] {
  font-size: 200px;
  line-height: 1;
}
.text-\[18px\] {
  font-size: 18px;
  line-height: 1;
}
.text-\[26px\] {
  font-size: 26px;
  line-height: 1;
}
.leading-none {
  line-height: 1;
}
.leading-normal {
  line-height: 1.5;
}
.leading-8 {
  line-height: 2rem;
}
.leading-6 {
  line-height: 1.5rem;
}
.m-auto {
  margin: auto;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}
.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.mx-1 {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}
.mr-4 {
  margin-right: 1rem;
}
.mb-2 {
  margin-bottom: 0.5rem;
}
.ml-2 {
  margin-left: 0.5rem;
}
.mt-4 {
  margin-top: 1rem;
}
.ml-4 {
  margin-left: 1rem;
}
.mt-2 {
  margin-top: 0.5rem;
}
.mr-8 {
  margin-right: 2rem;
}
.mb-5 {
  margin-bottom: 1.25rem;
}
.mb-10 {
  margin-bottom: 2.5rem;
}
.mt-5 {
  margin-top: 1.25rem;
}
.mb-4 {
  margin-bottom: 1rem;
}
.mt-8 {
  margin-top: 2rem;
}
.ml-6 {
  margin-left: 1.5rem;
}
.mr-3 {
  margin-right: 0.75rem;
}
.mr-2 {
  margin-right: 0.5rem;
}
.ml-5 {
  margin-left: 1.25rem;
}
.ml-8 {
  margin-left: 2rem;
}
.mb-6 {
  margin-bottom: 1.5rem;
}
.ml-3 {
  margin-left: 0.75rem;
}
.ml-15 {
  margin-left: 3.75rem;
}
.mt-1 {
  margin-top: 0.25rem;
}
.mr-6 {
  margin-right: 1.5rem;
}
.ml-1 {
  margin-left: 0.25rem;
}
.mr-5 {
  margin-right: 1.25rem;
}
.mt-6 {
  margin-top: 1.5rem;
}
.mb-0 {
  margin-bottom: 0px;
}
.last\:mb-0:last-child {
  margin-bottom: 0px;
}
.mr-1 {
  margin-right: 0.25rem;
}
.mt-3 {
  margin-top: 0.75rem;
}
.mb-8 {
  margin-bottom: 2rem;
}
.max-h-full {
  max-height: 100%;
}
.max-h-60 {
  max-height: 15rem;
}
.max-h-\[650px\] {
  max-height: 650px;
}
.max-h-100 {
  max-height: 25rem;
}
.max-w-full {
  max-width: 100%;
}
.max-w-md {
  max-width: 28rem;
}
.max-w-220 {
  max-width: 55rem;
}
.max-w-70 {
  max-width: 17.5rem;
}
.min-h-16 {
  min-height: 4rem;
}
.min-h-screen {
  min-height: 100vh;
}
.min-h-50 {
  min-height: 12.5rem;
}
.min-h-25 {
  min-height: 6.25rem;
}
.min-h-180 {
  min-height: 45rem;
}
.\!min-w-20 {
  min-width: 5rem !important;
}
.object-contain {
  -o-object-fit: contain;
  object-fit: contain;
}
.object-cover {
  -o-object-fit: cover;
  object-fit: cover;
}
.overflow-auto {
  overflow: auto;
}
.overflow-hidden {
  overflow: hidden;
}
.overflow-y-auto {
  overflow-y: auto;
}
.overflow-x-hidden {
  overflow-x: hidden;
}
.overflow-y-scroll {
  overflow-y: scroll;
}
.p-2 {
  padding: 0.5rem;
}
.p-1\.5 {
  padding: 0.375rem;
}
.p-4 {
  padding: 1rem;
}
.p-1 {
  padding: 0.25rem;
}
.p-6 {
  padding: 1.5rem;
}
.p-0 {
  padding: 0px;
}
.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}
.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.pb-4 {
  padding-bottom: 1rem;
}
.pt-4 {
  padding-top: 1rem;
}
.pl-3 {
  padding-left: 0.75rem;
}
.pt-8 {
  padding-top: 2rem;
}
.pt-3 {
  padding-top: 0.75rem;
}
.pb-3 {
  padding-bottom: 0.75rem;
}
.pb-5 {
  padding-bottom: 1.25rem;
}
.tab {
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
}
.static {
  position: static;
}
.fixed {
  position: fixed;
}
.absolute {
  position: absolute;
}
.relative {
  position: relative;
}
.sticky {
  position: -webkit-sticky;
  position: sticky;
}
.top-0 {
  top: 0px;
}
.left-0 {
  left: 0px;
}
.bottom-10 {
  bottom: 2.5rem;
}
.left-10 {
  left: 2.5rem;
}
.bottom-0 {
  bottom: 0px;
}
.right-10 {
  right: 2.5rem;
}
.top-8 {
  top: 2rem;
}
.right-0 {
  right: 0px;
}
.top-6\.5 {
  top: 1.625rem;
}
.resize {
  resize: both;
}
.shadow {
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0/0.1),0 1px 2px -1px rgb(0 0 0/0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color),0 1px 2px -1px var(--tw-shadow-color);
  -webkit-box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.text-gray-300 {
  --tw-text-opacity: 1;
  color: rgba(209, 213, 219, var(--tw-text-opacity));
}
.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
}
.text-red-500 {
  --tw-text-opacity: 1;
  color: rgba(239, 68, 68, var(--tw-text-opacity));
}
.text-blue-400 {
  --tw-text-opacity: 1;
  color: rgba(96, 165, 250, var(--tw-text-opacity));
}
.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-text-opacity));
}
.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-text-opacity));
}
.hover\:text-blue-500:hover {
  --tw-text-opacity: 1;
  color: rgba(59, 130, 246, var(--tw-text-opacity));
}
.text-blue-500 {
  --tw-text-opacity: 1;
  color: rgba(59, 130, 246, var(--tw-text-opacity));
}
.text-dark-50 {
  --tw-text-opacity: 1;
  color: rgba(74, 74, 74, var(--tw-text-opacity));
}
.text-gray-100 {
  --tw-text-opacity: 1;
  color: rgba(243, 244, 246, var(--tw-text-opacity));
}
.hover\:text-red-500:hover {
  --tw-text-opacity: 1;
  color: rgba(239, 68, 68, var(--tw-text-opacity));
}
.\!text-gray-500 {
  --tw-text-opacity: 1 !important;
  color: rgba(107, 114, 128, var(--tw-text-opacity)) !important;
}
.text-green-500 {
  --tw-text-opacity: 1;
  color: rgba(16, 185, 129, var(--tw-text-opacity));
}
.uppercase {
  text-transform: uppercase;
}
.lowercase {
  text-transform: lowercase;
}
.indent-2em {
  text-indent: 2em;
}
.content-disposition {
  content: "disposition";
}
.content-container {
  content: "container";
}
.content-wrap {
  content: "wrap";
}
.select-none {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.visible {
  visibility: visible;
}
.break-all {
  word-break: break-all;
}
.w-screen {
  width: 100vw;
}
.w-full {
  width: 100%;
}
.w-40 {
  width: 10rem;
}
.w-1\/2 {
  width: 50%;
}
.w-60 {
  width: 15rem;
}
.w-2\/3 {
  width: 66.666667%;
}
.w-1\/3 {
  width: 33.333333%;
}
.w-6 {
  width: 1.5rem;
}
.w-10 {
  width: 2.5rem;
}
.w-80 {
  width: 20rem;
}
.w-70 {
  width: 17.5rem;
}
.w-100 {
  width: 25rem;
}
.w-17 {
  width: 4.25rem;
}
.w-\[1050px\] {
  width: 1050px;
}
.\!w-30 {
  width: 7.5rem !important;
}
.\!w-50 {
  width: 12.5rem !important;
}
.w-30 {
  width: 7.5rem;
}
.w-35 {
  width: 8.75rem;
}
.w-50 {
  width: 12.5rem;
}
.w-\[480px\] {
  width: 480px;
}
.w-17\/20 {
  width: 85%;
}
.w-\[600px\] {
  width: 600px;
}
.w-90 {
  width: 22.5rem;
}
.z-1000 {
  z-index: 1000;
}
.z-5 {
  z-index: 5;
}
.z-1 {
  z-index: 1;
}
.z-2 {
  z-index: 2;
}
.ease-in-out {
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.duration {
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
}
.animated {
  -webkit-animation-duration: 1000ms;
  -webkit-animation-fill-mode: both;
  animation-duration: 1000ms;
  animation-fill-mode: both;
}
.blur {
  --tw-blur: blur(8px);
}