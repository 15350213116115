@keyframes swing {
  20% {
    transform: rotate3d(0, 0, 1, 15deg);
  }

  40% {
    transform: rotate3d(0, 0, 1, -10deg);
  }

  60% {
    transform: rotate3d(0, 0, 1, 5deg);
  }

  80% {
    transform: rotate3d(0, 0, 1, -5deg);
  }

  100% {
    transform: rotate3d(0, 0, 1, 0deg);
  }
}

@keyframes drift {
  0% {
    transform-origin: top left;
    animation-timing-function: ease-in-out;
  }

  20%,
  60% {
    transform: rotate3d(0, 0, 1, 80deg);
    transform-origin: top left;
    animation-timing-function: ease-in-out;
  }

  40%,
  80% {
    opacity: 1;
    transform: rotate3d(0, 0, 1, 60deg);
    animation-timing-function: ease-in-out;
    transform-origin: top left;
  }

  100% {
    opacity: 0;
    transform: translate3d(0, 700px, 0);
  }
}

@keyframes bounce {
  0% {
    transform: translateY(-500px);
    animation-timing-function: ease-in;
    opacity: 0;
  }

  38% {
    transform: translateY(0);
    animation-timing-function: ease-out;
    opacity: 1;
  }

  55% {
    transform: translateY(-65px);
    animation-timing-function: ease-in;
  }

  72%,
  90%,
  100% {
    transform: translateY(0);
    animation-timing-function: ease-out;
  }

  81% {
    transform: translateY(-28px);
    animation-timing-function: ease-in;
  }

  95% {
    transform: translateY(-8px);
    animation-timing-function: ease-in;
  }
}

.slide-in-enter {
  opacity: 0;
  transform: translateX(-25px);
}

.slide-in-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: transform 0.4s cubic-bezier(0.23, 1, 0.32, 1) 100ms,
    opacity 0.4s cubic-bezier(0.23, 1, 0.32, 1) 100ms;
  transform-origin: center top;
}

.slide-in-exit {
  opacity: 1;
}

.slide-in-exit-active {
  opacity: 0;
  transform: translateX(-25px);
  transition: transform 0.4s cubic-bezier(0.23, 1, 0.32, 1) 100ms,
    opacity 0.4s cubic-bezier(0.23, 1, 0.32, 1) 100ms;
  transform-origin: center top;
}

// 404
.page-not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;
  color: #555555;

  .title {
    font-size: 200px;
    font-weight: bold;
    position: relative;
  }

  .title-main {
    position: relative;
    z-index: 1;
    animation: swing 1.5s ease-in-out alternate;
    animation-duration: 1s;
    animation-fill-mode: both;
    transform-origin: top center;
  }

  .title-shadow {
    position: absolute;
    top: 0;
    color: #fff;
    text-shadow: 0 0 30px #ddd;
    pointer-events: none;
  }

  .subtitle {
    font-size: 18px;
  }

  .drift {
    animation-duration: 3s;
    animation-name: drift;
  }
}
