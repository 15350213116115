.declare-page {
  .banner {
    width: 100%;
    height: auto;
    background-color: #fff;
  }
  .content {
    background-color: #f0f2f5;
    .content-container {
      width: 1050px;
      margin: 0 auto;

      // .ant-tabs-content {
      //   background: #fff;
      //   padding: 20px;
      //   border-radius: 8px;
      // }
      // .ant-card-meta-title {
      //   color: #2b2b2b;
      //   font-size: 17px;
      //   font-weight: 700;
      //   width: 100%;
      //   display: block;
      //   white-space: normal;
      //   margin-top: 32px;
      // }
    }
  }

  .ant-card-actions {
    li {
      span {
        color: #515151;
        font-size: 17px;
        font-weight: 700;
        width: 100%;
        display: block;
      }
    }
  }

  .sub-menu {
    display: inline-block;
    font-size: 16px;
    background-color: #eee;
    height: min-content;

    .menu-head {
      // mixin
      height: 50px;
      line-height: 50px;
      text-align: center;
      padding: 0 20px;
      background-color: #3671c9;
      color: #fff;
      height: 50px;
      line-height: 50px;
    }

    .menu-item {
      height: 50px;
      line-height: 50px;
      text-align: center;
      padding: 0 30px;
      min-width: 180px;
      cursor: pointer;
      position: relative;

      &:hover {
        background-color: #ddd;
      }

      .menu-item-indicator {
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}
