/* 浏览器默认大小为16px, 62.5% * 16 = 10px = 1rem */
/* html {
  font-size: 62.5%;
} */

ol {
  padding: 0;
  margin: 0;
}

.page {
  height: 100vh;
}
