.postDrawer{
    .zhuloudes{
        border: 1px solid #CCC;
        
    }
    .forumTime{
      cursor: pointer;
    }
    .ant-image{
        width: 70px;
        height: 70px;
        margin: 16px 42px;
    }
    .detail-item{
        margin-left: 0px !important; 
        margin-right: 0px !important;
        .detailcontent{
          padding-bottom: 85px;
        }
        .delete{
          color: red;
          margin-left: 20px;
          cursor: pointer;
        }
        .lounum{
          color: rgb(59, 59, 255);
          margin-left: 20px;
        }
      }
    .edit{
        margin-top: 30px;
    }
    .detail-cover{
        width: 70px;
        height: 70px;
        border: 1px solid #CCC;
       
    }
    .ant-list-item{
        padding: 0px;
        border: 1px solid #DDD;
        margin-top: 10px;
    }
    .ant-list-split .ant-list-item:last-child {
         border-bottom: 1px solid #DDD; 
    }
    .detailsname{
        padding-bottom: 20px;
        text-align: center;
    }
}
