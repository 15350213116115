// 页面主体
.page-main {
  min-height: calc(100% - 76px);
  display: flex;
  flex-direction: column;
}

// DepTree组件
.edit-icon:hover {
  color: var(--ant-primary-color-hover);
  transform: scale(1.3);
  transition: all 0.1s;
}

// ant-pop
.ant-popover {
  &.pop-padding-none {
    .ant-popover-inner-content {
      padding: 0;
    }
  }
}

.uploader {
  .ant-upload.ant-upload-btn {
    padding: 0;
  }
}

// labReport组件
.lab-report-table {
  width: 700px;
  max-height: 700px;
  overflow-y: scroll;

  td {
    border: #000 1px solid;
    padding: 6px;
    word-wrap: break-word;
    word-break: break-all;
  }

  .nonepad {
    padding: 0;
  }

  .nonepad-inner {
    display: flex;
  }

  .nonepad-inner.all > * {
    padding: 6px;
  }

  .rt-row-name {
    font-weight: bold;
    font-size: 14px;
    color: #393939;
    padding-right: 1em;
    // text
  }

  div.rt-row-name {
    border-right: #000 1px solid;
    min-width: 200px;
    padding: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .rtgrid-top {
    width: 100%;
    display: flex;
    border-bottom: #000 1px solid;
  }

  .rt-row-cell {
    flex-grow: 1;
    padding: 6px;
  }

  .rt-row-cell:first-child {
    border-right: 1px solid #000;
  }

  .rt-content {
    width: 100%;
    img {
      max-width: 100%;
    }
  }
}

// role card
.role-card {
  background: #fff;
  border-radius: 4px;
  border: 1px solid #eee;
  box-shadow: 0 5px 10px #eee;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  padding: 20px;
  margin-bottom: 10px;

  &:hover {
    background-color: #f1f1f1;
  }

  .opts {
    margin-left: auto;
    flex-shrink: 0;

    .opt {
      display: inline-block;
      cursor: pointer;
      width: 28px;
      height: 28px;
      margin-left: 12px;
      border-radius: 50%;
      background-color: #eef4ff;
      color: #868686;
      text-align: center;
      line-height: 28px;
      &:hover {
        background-color: #d0e0ff;
      }
    }
  }

  &.checked {
    // background: linear-gradient(60deg, #fcffcc, #c4ffd7);
    background: linear-gradient(45deg, #ebf4f5, #b5c6e0);
  }
}

// 通知面板
.notice-popover {
  .notice-pop-title {
    font-size: 16px;
    padding: 6px 20px;
  }

  .notice-list {
    width: 400px;
    max-height: 400px;
    overflow-y: auto;
  }

  .notice-top {
    border-bottom: 1px solid #eee;
    padding: 6px 20px;
  }

  .notice-list-item {
    padding: 15px 20px;
    border-bottom: 1px solid #eee;

    &:last-child {
      border: none;
    }
  }

  .notice-area {
    cursor: pointer;
  }

  .notice-btm {
    border-top: 1px solid #eee;
    padding: 10px 0;
    text-align: center;
  }
}

// 富文本内容
.rich-render {
  img {
    max-width: 100%;
  }
}

span[data-w-e-type="attachment"] {
  text-decoration: underline;
  color: #2b7de7;
  display: inline-block;
  cursor: pointer;
  border: #ccc 1px dashed;
  background-color: #f7f7f7;
  padding: 0 5px;
  border-radius: 3px;
  margin: 5px;
}

// 表格查询栏
.ant-space-item .ant-form-item {
  margin-right: 0;
}

// 视频播放页
.play-page {
  .page-main-wrap {

    .page-main {
      max-width: 1400px;
      margin: 0 auto;
      padding: 40px;
    }

    .player-wraper {
      display: flex;

      .player-left-container {
        flex-grow: 1;
        min-width: 750px;
      }

      .player-right-container {
        margin-left: 30px;
        flex-shrink: 0;
        width: 260px;
      }
    }
  }
}

// 视频页答题卡
.answer-card {
  // position: sticky;
  padding: 15px;
  background-color: #eee;
  border-radius: 4px;
  max-height: 580px;
  overflow-y: auto;

  div {
    cursor: pointer;
  }
}

.declare-lab-card {
  background-color: #fff;
  transition: all 0.2s;
  border: 1px solid #eee;

  &:hover {
    box-shadow: 0px 1px 10px #cacaca;
  }

  .win {
    width: 100%;
    height: 210px;
    position: relative;
    overflow: hidden;
    background-size: cover;

    &:hover {
      .intro {
        top: 0;
        background-color: rgba(0, 0, 0, 0.3);
        backdrop-filter: blur(10px);
      }
    }

    .intro {
      position: absolute;
      top: 210px;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 20px;
      z-index: 2;
      transition: all 0.2s;
      color: #fff;

      .intro-title {
        font-size: 20px;
        padding-bottom: 8px;
        border-bottom: 1px solid #fff;
      }
    }

    .corner {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
      padding: 3px 10px;
      background-color: #fff;
      color: #ff6e6e;
      border-bottom-left-radius: 5px;
      // border: #ff6161 1px solid;
      box-shadow: rgba(126, 126, 126, 0.3) -2px 2px 4px;
    }
  }

  .title {
    padding: 10px 0;
    text-align: center;
    font-size: 18px;
    border-bottom: 1px solid #e8e8e8;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .btm {
    padding: 8px 0;
    text-align: right;

    .entrance {
      padding: 5px 15px;
      display: inline-block;
      color: #9f9f9f;
      font-size: 16px;
      cursor: pointer;

      &:hover {
        background-color: #87befc;
        color: #fff;
      }

      .entrance-icon {
        border-radius: 9999px;
      }
    }
  }
}

.practice-page {
  .page-main-wrap {

    .page-main {
      max-width: 1400px;
      margin: 0 auto;
      padding: 40px;
    }

    .player-wraper {
      display: flex;

      .player-left-container {
        flex-grow: 1;
        min-width: 750px;

        .answer-area{
          padding: 20px;
          border: #eee 1px solid;
        }

      }

      .player-right-container {
        margin-left: 30px;
        flex-shrink: 0;
        width: 260px;
      }
    }
  }
}

// 美化webkit滚动条
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: @primary-2;
}
::-webkit-scrollbar-thumb:hover {
  background-color: @primary-4;
}
::-webkit-scrollbar-track {
  background-color: #eee;
}
